import React, { useState } from 'react'
import styles from './property.module.scss'
import { BATH, BED, TOILET } from '../icons'
import { navigate } from 'gatsby'
import Summary from './summary'
import Badge from './badge'
import Fav from './fav'
import * as Routes from '../../routes/index'
import { Carousel } from 'react-responsive-carousel'
import { isLoggedIn } from '../../services/auth'

const PropertyCarousel = ({ images }) => {
  return (
    <Carousel
      thumbWidth={100}
      autoPlay={false}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <button
          type="button"
          aria-label={label}
          className={`control-arrow control-next`}
          onClick={e => {
            e.stopPropagation()
            onClickHandler()
          }}
          tabIndex="-1"
        />
      )}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <button
          type="button"
          aria-label={label}
          className={`control-arrow control-prev`}
          onClick={e => {
            e.stopPropagation()
            onClickHandler()
          }}
          tabIndex="-1"
        />
      )}
    >
      {images.map((image, index) => {
        return (
          <div key={index}>
            <img src={image} alt={image.filename} className="img-fluid d-block rounded-top" />
          </div>
        )
      })}
    </Carousel>
  )
}

const PropertySummary = ({ beds, baths, toilets }) => (
  <div>
    <Summary>
      <BED />
      {isNaN(beds) ? '' : 'x'}
      {beds}
    </Summary>
    <Summary>
      <BATH />
      {isNaN(baths) ? '' : 'x'}
      {baths}
    </Summary>
    <Summary>
      <TOILET />
      {isNaN(toilets) ? '' : 'x'}
      {toilets}
    </Summary>
  </div>
)

const actionButtonKeydownHandler = (event, slug) => {
  // The action button is activated by space on the keyup event, but the
  // default action for space is already triggered on keydown. It needs to be
  // prevented to stop scrolling the page before activating the button.
  if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
    event.preventDefault()
    viewProperty(slug)
  }
}

const viewProperty = slug => {
  navigate(`${Routes.PROPERTY}${slug}`)
}

const PropertyCard = ({ images, street, beds, baths, toilets, propertyType, slug, id, isFav, index }) => {
  const [fav, setFav] = useState(isFav)
  return (
    <div
      className={`${styles.card_property} card`}
      onClick={() => {
        viewProperty(slug)
      }}
      onKeyDown={e => {
        actionButtonKeydownHandler(e, slug)
      }}
      role={'button'}
      tabIndex={index}
      data-id={slug}
      data-index={index}
    >
      <PropertyCarousel images={images} />
      <div className={styles.topElement}>
        <Badge parent="card">{propertyType}</Badge>
      </div>
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-4 pr-0">
            <span className={styles.card_street}>{street}</span>
          </div>
          <div className="col-7 pl-0 pr-1 text-right">
            <PropertySummary baths={baths} beds={beds} toilets={toilets} />
          </div>
          <div className="col-1 px-0 text-center">
            {isLoggedIn() && <Fav fav={fav} setFav={setFav} propertyId={id} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyCard
