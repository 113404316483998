//Google integration
const API_KEY = process.env.GATSBY_MAPS_KEY

export const loadMap = initMap => {
  let script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=initMap`
  script.defer = true
  window.initMap = initMap
  document.head.appendChild(script)
}
