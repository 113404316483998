import { isBrowser } from '../../services/auth'
import queryString from 'querystring'
import { Link } from 'gatsby'
import React from 'react'

let query

const active = {
  backgroundColor: '#e5a191',
  color: '#fff'
}
/**
 * Pagination element
 * @param {Object} props - Component's props
 * @param {string|number} props.value - Index of the current page
 * @param {string} [props.text] - Text to be displayed for the pagination item
 * @return {function(): JSX.Element}
 * @constructor
 */
const PaginationItem = ({ value, text }) => {
  if (isBrowser()) query = queryString.parse(window.location.search.substr(1))
  query.page = value.toString()
  const search = `?${queryString.stringify(query)}`
  const isActive = ({ location }) => {
    const target = location.search || `?page=1`
    return target === search ? { style: active } : {}
  }

  return (
    <li className="page-item">
      <Link className="page-link" to={search} getProps={isActive}>
        {text || value}
      </Link>
    </li>
  )
}

export default PaginationItem
