const { skips } = require('../constants')
const { CHUNK_SIZE } = require('../constants')
const { request, client } = require('./index')
const ANY = 'Any'
const STUDIO = 'Studio'
const RADIUS = 100000
const GTE = '([5-9]|([1-9][0-9]))'
const LTE = '^([0-#]){1}$'
let params,
  filter = 'isavailable: {eq: true},'
let price
const PROPS = `{
  id
  title
  slug
  bathrooms
  bedrooms
  toilets
  locationAddress {
    number
    street
    addressLine2
    suburb
    postCode
  }
  propertyType
  gallerySection {
    album {
      responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 400, h: 230}) {
        src
      }
    }
  }
  isavailable
}`

const PropertyQuery = `query PropertyQuery($slug: String!) {
  propertyCollection(filter: {slug: { eq: $slug }}) {
    id
    title
    slug
    propertyType
    listingType
    listingPrice
    monthlyPrice
    hidePrice
    securityBond
    dateAvailable
    bedrooms
    bathrooms
    ensuites
    toilets
    livingAreas
    outdoorArea
    houseSize
    landSize
    agent {
      name
      email
      agency
      phoneNumber
    }
    locationAddress {
      number
      street
      addressLine2
      suburb
      postCode
    }
    location {
      latitude
      longitude
    }
    statementInformation {
      url
    }
    ecoFriendlyFeatures {
      ecoFriendlyTitle
      greyWaterSystem
      solarPanels
      solarHotWater
      waterTank
    }
    healingCooling {
      healingCoolingTitle
      airConditioning
      ductedCooling
      ductedHeating
      evaporativeCooling
      gasHeating
      hydronicHeating
      openFireplace
      reverseCycleAirCon
      splitSystemAirCon
      splitSystemHeating
    }
    indoorFeatures {
      indoorTitle
      alarmSystem
      broadbandInternetAvail
      builtInWardrobes
      dishwasher
      ductedVacuum
      floorboards
      furnished
      gym
      insideSpa
      intercom
      payTvAccess
      rumpusRoom
      study
      workshop
    }
    outdoorFeatures {
      balcony
      courtyard
      deck
      entertainmentArea
      fullyFenced
      outsideSpa
      remoteGarage
      secureParking
      shed
      swimmingPool
      tennisCourt
    }
    gallerySection {
      portraitPhoto {
        alt
        filename
        responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 1200, h: 630}) {
          src
        }
      }
      album {
        filename
        responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 1200, h: 630}) {
          src
        }
      }
      videoUrl {
        url
        thumbnailUrl
      }
      floorPlan {
        url
      }
    }
    listingDescription
    inspection {
      inspectionDayName
      openTime
      closeTime
    }
  }
}
`
const PropertyLockBoxQuery = `query PropertyLockBoxQuery($property: ItemId) {
  propertyCollection(filter: {id: { eq: $property }}) {
    lockbox {
      lockid
      iglooapikey
    }
  }
}
`

const HomePropertiesQuery = `
query TopSearchQuery {
    allPropertyCollections (first: 6, orderBy: [_createdAt_DESC], filter: {isavailable: {eq: true}}) {
    id
    title
    slug
    bathrooms
    bedrooms
    toilets
    locationAddress {
      number
      street
      addressLine2
      suburb
      postCode
    }
    propertyType
    gallerySection {
      album {
        responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 400, h: 230}) {
          src
        }
      }
    }
  }  
}`

/**
 * Gets the list of available property on DatoCMS filtered by preference, lat, lng, type, bedrooms, bathrooms and price
 * @param {string} preference - user preference for buy or rent
 * @param {number} lat - Property location latitude
 * @param {number} lng - Property location longitude
 * @param {string} type - Property type (House|Apartment & Unit|Townhouse|Villa|Commercial)
 * @param {string} bedrooms - Number of min of bedrooms in the property (default any)
 * @param {string} bathrooms - Number of min of bathrooms in the property (default any)
 * @param {string} priceMin - Min price of the property
 * @param {string} priceMax - Max price of the property
 * @param {string | number} page - The index of the next chunk to show
 * @return {Promise<{allPropertyCollections: Object[], _allPropertyCollectionsMeta: Object}>}
 */
module.exports.getProperties = async function ({
  preference,
  lat,
  lng,
  type,
  bedrooms,
  bathrooms,
  priceMin,
  priceMax,
  page = 1
}) {
  const skip = skips(page)
  const first = CHUNK_SIZE
  let PROPERTIES_QUERY = `query AllProperties `
  let variables = {}
  if (preference && lat && lng) {
    getFilters(type, bedrooms, bathrooms, priceMin, priceMax)
    PROPERTIES_QUERY += `(${params}) { allPropertyCollections(first: ${first}, skip: ${skip}, filter: { ${filter} }) ${PROPS} _allPropertyCollectionsMeta (filter: { ${filter} }) {
        count
      }
    }`
    variables = {
      ...variables,
      preference,
      lat: parseFloat(lat),
      lng: parseFloat(lng),
      propertyType: type,
      bedrooms,
      bathrooms,
      price
    }
  } else {
    PROPERTIES_QUERY += `{ allPropertyCollections(first: ${first}, skip: ${skip}, filter: {${filter}}) ${PROPS}  _allPropertyCollectionsMeta (filter: { ${filter} }) {
      count
    }
  }`
  }
  // console.log(PROPERTIES_QUERY)
  // console.log(variables)
  return request({ query: PROPERTIES_QUERY, variables })
}

/**
 * Gets the property object from the CMS including the creator object
 * @param {string} id - Property's id
 * @return {Promise<Object>}
 */
module.exports.getPropertyCreator = function (id) {
  return client.items.find(id, { version: 'published' })
}

/**
 * Gets the preview content form DatoCMS of properties listed in the shortlist array
 * @param {string[]} shortlist - An array of properties' id
 * @param {string | number} page - The index of the next chunk to show
 * @return {Promise<{allPropertyCollections: Object[], _allPropertyCollectionsMeta: Object}>}
 */
module.exports.getShortlist = async function ({ shortlist, page = 1 }) {
  const skip = skips(page)
  const first = CHUNK_SIZE
  let PROPERTIES_QUERY = `query AllProperties `
  let variables = { id: shortlist }

  PROPERTIES_QUERY += `($id: [ItemId]) { allPropertyCollections(first: ${first}, skip: ${skip}, filter: {id: {in: $id}}) ${PROPS}  _allPropertyCollectionsMeta (filter: {id: {in: $id}}) {
    count
  }
  }`

  return request({ query: PROPERTIES_QUERY, variables })
}

/**
 * Gets all the property information from DatoCMS based on the property's slug
 * @param {string} slug - property's slug
 * @return {Promise<{propertyCollection: Object}>}
 */
module.exports.getPropertyBySlug = async function (slug) {
  return request({ query: PropertyQuery, variables: { slug } })
}

/**
 * Gets the lockbox assigned to a Property on DatoCMS
 * @param {string} id - Property's id
 * @return {Promise<{propertyCollection: Object}>}
 */
module.exports.getLockBoxByProperty = async function (id) {
  return request({ query: PropertyLockBoxQuery, variables: { property: id } })
}

/**
 * Gets all the property information from DatoCMS based on the property's slug
 * @param {string} id - property's id
 * @return {Promise<{propertyCollection: Object}>}
 */
module.exports.getPropertyById = function (id) {
  let PROPERTIES_QUERY = `query PropertyQuery($id: ItemId) {
  propertyCollection(filter: {id: { eq: $id }}) `

  PROPERTIES_QUERY += PROPS
  PROPERTIES_QUERY += ` }`
  const variables = {
    id
  }

  return request({ query: PROPERTIES_QUERY, variables })
}

/**
 * Gets the 6 recently added property on DatoCMS
 * @return {Promise<{allPropertyCollections: Object[]}>}
 */
module.exports.getTopProperties = function () {
  return request({ query: HomePropertiesQuery, variables: {} })
}
/**
 * Gets the necessary filters and params for the property's query based on the values of the params
 * @param {string} type - Property type (House|Apartment & Unit|Townhouse|Villa|Commercial)
 * @param {string} bedrooms - Number of min of bedrooms in the property (default any)
 * @param {string} bathrooms - Number of min of bathrooms in the property (default any)
 * @param {string} priceMin - Min price of the property
 * @param {string} priceMax - Max price of the property
 */
const getFilters = (type, bedrooms, bathrooms, priceMin, priceMax) => {
  params = '$preference: String, $lat: FloatType!, $lng: FloatType! '
  filter = `
    listingType: { eq: $preference },
    location: { near: { latitude: $lat, longitude: $lng, radius: ${RADIUS} } }
    `
  getQueryOption('propertyType', type)
  getQueryOption('bedrooms', bedrooms)
  getQueryOption('bathrooms', bathrooms)

  const parsedMin = parseOption(priceMin) || 0
  const parsedMax = parseOption(priceMax) || 0

  if (priceMin || priceMax) {
    if (parsedMin > parsedMax) {
      filter += `, listingPrice: { gte: $price }`
      price = parsedMin
    } else if (parsedMin < parsedMax) {
      filter += `, listingPrice: { lte: $price }`
      price = parsedMax
    } else {
      filter += `, listingPrice: { eq: $price }`
      price = parsedMax
    }
    params += ', $price: FloatType'
  }
}

/**
 * Gets the filter definition for the Property's query based on the value
 * @param {string} option - filter name
 * @param {string | number} value - filter value
 * @return {null | string}
 */
const getQueryOption = (option, value) => {
  const parsedValue = parseOption(value)
  if (value === ANY || !value) {
    return null
  } else if (value === STUDIO || option === 'propertyType') {
    filter += `, ${option}:{ eq: $${option} }`
    params += `, $${option}: String`
  } else if (value !== ANY && value.includes('+')) {
    filter += `, ${option}: { matches: { pattern: "${GTE}" }}`
  } else if (value !== ANY) {
    filter += `, ${option}: { matches: { pattern: "${LTE.replace('#', parsedValue)}"}}`
  }
}

/**
 * Gets the number value of a filter option
 * @param {string} value - Filter's value
 * @return {number}
 */
const parseOption = value => {
  return value ? parseFloat(value.includes('$') ? value.substr(value.indexOf('$') + 1).replace(',', '') : value) : 0
}
