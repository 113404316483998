import React, { useEffect, useState } from 'react'
import Empty from './empty'
import Loader from './loader'
import { isBrowser } from '../../services/auth'
import { CHUNK_SIZE } from '../../constants'

/**
 * Wraps a component to fetch data,
 * while data is fetched a Loading component is returned
 * if results are found it sets the number of pages and the Properties value and returns the wrapped component
 * otherwise the Empty component is returned
 * @param {function(): JSX.Element} WrappedComponent - Component to be wrapped
 * @param {Object} boilerplate - A boilerplate error and result
 * @param {Object} error - Error Object
 * @param {string} error.message - No result's message
 * @param {string} [error.cta] - CTA's text (it always redirects to dashboard page)
 * @param {boolean} [error.fullWidth=false] - Flag to set component full width
 * @param {Object} result - Result Object
 * @param {Object[]} result.properties - Properties array
 * @param {string | number} [result.pages] - Index of the current page
 * @param {function} [fetchFunction] - Fetch function
 * @param {boolean} [fav=false] - Flag to set the value of the fav property of each Property
 * @return {function(): JSX.Element}
 * @constructor
 */
const Data = (WrappedComponent, { error, result }, fetchFunction, fav = false) => {
  const WithData = () => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(result.properties)
    const [pages, setPages] = useState(result.pages)

    useEffect(() => {
      if (fetchFunction && isBrowser()) {
        const fetchData = async () => {
          let { allPropertyCollections: properties, _allPropertyCollectionsMeta: items } = await fetchFunction()
          properties.forEach(item => (item.fav = fav))
          const elements = items ? items.count : 0
          setPages(Math.ceil(elements / CHUNK_SIZE))
          setValues(properties)
          setLoading(false)
        }
        setLoading(true)
        fetchData()
      }
    }, [])

    const Error = <Empty {...error} />
    const Result = <WrappedComponent properties={values} pages={pages} />
    const Loading = <Loader />
    return loading ? Loading : values.length === 0 ? Error : Result
  }
  WithData.displayName = `WithData(${getDisplayName(WrappedComponent)})`

  return WithData
}

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default Data
