import React from 'react'
import AuthProvider from '../../provider/auth'
import NavBar from '../navBar'
import styles from './layout.module.scss'
import Header from './headerRenter'
import Footer from './footerRenter'
import News from './news'
import { useLocation } from '@reach/router'
import { LOGIN, SIGNIN } from '../../routes'

const LayoutRenter = ({ children }) => {
  const location = useLocation()
  const isOnAuthPage = location.pathname === LOGIN || location.pathname === SIGNIN
  const style = isOnAuthPage ? { marginBottom: '0px' } : {}
  return (
    <div>
      <News />
      <div className={styles.layout_renter} style={style}>
        <AuthProvider>
          <Header />
          <NavBar />
          <div className="container">{children}</div>
        </AuthProvider>
        <Footer />
      </div>
    </div>
  )
}

export default LayoutRenter
