const moment = require('moment')
const WeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

/**
 * @typedef inspection
 * @type {Object}
 * @property {string} inspectionDayName - Day's name
 * @property {string} openTime - Open Time in 24 h format
 * @property {string} closeTime - Close Time in 24 h format
 */

/**
 * @typedef booking
 * @type {Object}
 * @property {string} propertyId - Property's id
 * @property {Date} date - Booking's date
 * @property {string} address - Property's address
 * @property {string} slug - Property's slug
 * @property {string} image - Property's portrait photo url
 */

/**
 * Gets the number of the day of the month and the inspection times based on the dayTimeSelected
 * @param {inspection[]} inspections - Property's inspections time Objects containing inspectionDayName, openTime and closeTime
 * @param {moment.Moment} dayTimeSelected - Date for booking inspection
 * @return {{dateSelected: moment | number, inspectionDay: inspection}}
 */
const getInspectionDetails = (inspections, dayTimeSelected) => {
  const dayNameSelected = dayTimeSelected.format('dddd')
  const dateSelected = dayTimeSelected.date()
  const inspectionDay = inspections.find(inspection => inspection.inspectionDayName === dayNameSelected)
  return { dateSelected, inspectionDay }
}

/**
 * Get the open hours for inspections
 * @param {inspection} inspectionDay - Property's inspection time for a specific day
 * @param {number} date - day of the month in number (from 1 - 31)
 * @return {{daySelectedCloses: moment.Moment, daySelectedOpens: moment.Moment}}
 */
const getOpenHours = (inspectionDay, date) => {
  const openHour = parseInt(inspectionDay.openTime.split(':')[0])
  const closeHour = parseInt(inspectionDay.closeTime.split(':')[0])
  const daySelectedOpens = moment().date(date).hours(openHour).startOf('hour')
  const daySelectedCloses = moment().date(date).hours(closeHour).startOf('hour')
  return { daySelectedOpens, daySelectedCloses }
}

/**
 * Format the address object into a human language address
 * @param {Object} addressObj - Property address object
 * @param {string} addressObj.number
 * @param {string} addressObj.street
 * @param {string} addressObj.addressLine2
 * @param {string} addressObj.suburb
 * @param {string} addressObj.postCode
 * @return {string}
 */
module.exports.formatAddress = function (addressObj) {
  const { number, street, addressLine2, suburb, postCode } = addressObj
  if (addressLine2) {
    return `${number} ${street}, ${addressLine2}, ${suburb} ${postCode}`
  } else {
    return `${number} ${street}, ${suburb} ${postCode}`
  }
}

/**
 * Gets the fav state value of a property based on the profile shortlist
 * @param {Object} profile - user profile
 * @param {Array<string>} profile.shortlist - properties shortlist
 * @param {string} propertyId - Property's id
 * @return {boolean}
 */
module.exports.getFavState = function (profile, propertyId) {
  const shortlist = profile ? profile.shortlist : []
  return shortlist.includes(propertyId)
}

/**
 * Obtains the bookings added to the cart of an specific property and return the actual bookings of a property combined with ones added to user's cart
 * @param {Object} profile - user profile
 * @param {booking[]} profile.cart - user cart
 * @param {Object[]} events - Property's bookings
 * @param {Date} events.date - Property's booking date
 * @param {String} propertyId - Property's id
 * @return {Array}
 */
module.exports.getEvents = function (profile, events, propertyId) {
  const cart = profile ? profile.cart : []
  const bookingItem = cart.find(item => item.propertyId === propertyId) || null
  const bookingValue = bookingItem
    ? {
        date: bookingItem.date,
        id: bookingItem.propertyId
      }
    : bookingItem
  return bookingItem ? [...events, bookingValue] : events
}

/**
 * Gets the business hours configuration based on the property's inspections time for fullcalendar.io
 * @param {inspection[]} inspections - Property's inspections time
 * @return {Array} - Array of fullcalendar.io business hours
 */
module.exports.getCalendarOpenHours = inspections => {
  return inspections.map(inspection => {
    const dayOfWeek = WeekDays.findIndex(day => day === inspection.inspectionDayName)
    return {
      daysOfWeek: [dayOfWeek],
      startTime: inspection.openTime,
      endTime: inspection.closeTime
    }
  })
}

/**
 * Gets the time available for bookings of a property based on a day and time
 * @param {inspection[]} inspections - Property's inspections time
 * @param {Date} daySelected - Date string containing the day and time for the booking
 * @return {{daySelectedCloses: moment.Moment, daySelectedOpens: moment.Moment}}
 */
module.exports.getTimeAvailable = (inspections, daySelected) => {
  const dayTimeSelected = moment(daySelected)
  const { dateSelected, inspectionDay } = getInspectionDetails(inspections, dayTimeSelected)
  if (inspectionDay) {
    return getOpenHours(inspectionDay, dateSelected)
  }
}

/**
 * Gets the availability of a day for possible bookings at a specific date and time
 * @param {inspection[]} inspections - Property's inspections time
 * @param {Date} daySelected - Date string containing the day and time for the booking
 * @return {boolean}
 */
module.exports.isDayAvailable = (inspections, daySelected) => {
  const dayTimeSelected = moment(daySelected)
  const { dateSelected, inspectionDay } = getInspectionDetails(inspections, dayTimeSelected)
  if (inspectionDay) {
    const { daySelectedOpens, daySelectedCloses } = getOpenHours(inspectionDay, dateSelected)
    return (
      dayTimeSelected.isBetween(daySelectedOpens, daySelectedCloses, 'hour', '[]') && isAFutureDate(dayTimeSelected)
    )
  }
  return false
}

/**
 * Verifies if the inspection date is a future date from now
 * @param {string|Date} date - Inspection date
 * @return {boolean}
 */
const isAFutureDate = date => {
  const inspectionDate = moment(date)
  const nowOneHourAhead = moment().startOf('hour')
  return inspectionDate.isSameOrAfter(nowOneHourAhead)
}
module.exports.isAFutureDate = isAFutureDate

/**
 * Gets the new value of shopping cart updating the date of a property's booking already added
 * @param {booking[]} cart - user cart
 * @param {Object} booking - Property's bookings
 * @param {Date} booking.date - Property's booking date
 * @param {string} booking.propertyId - Property's id
 * @return {Object[]}
 */
module.exports.getCartFiltered = (cart, booking) => {
  const cartFiltered = cart.filter(item => item.propertyId !== booking.propertyId)
  const currentBooking = cart.find(item => item.propertyId === booking.propertyId)
  let newBooking = booking
  if (currentBooking) {
    newBooking = {
      ...currentBooking,
      date: booking.date
    }
  }
  return [...cartFiltered, newBooking]
}

/**
 * Gets the total amount of money to paid for the shopping cart
 * @param {booking[]} items - user cart
 * @param {number} bookingPrice - Price per booking
 * @return {Number} - Shopping cart amount
 */
module.exports.calculateOrderAmount = (items, bookingPrice) => {
  return items.length * bookingPrice
}

/**
 * Get the next available time slot for booking inspection
 * @param {booking[]} events - Property's booking events
 * @param {inspection[]} inspectionsTime - Property's inspection time
 * @param {string} id - Property's id
 * @return {{date: string, id: string}} - Next available event
 */
module.exports.getSuggestedEvent = (events, inspectionsTime, id) => {
  const now = moment().startOf('hour')
  let suggestedTime = moment().startOf('hour').add(1, 'h')
  const daysAvailableForBooking = inspectionsTime.map(item => item.inspectionDayName)
  let continueLooking = true
  let movingToNextDay = true
  let targetBookingDayIndex = -1
  let nowDay = suggestedTime.format('dddd')
  let openHour,
    closeHour = 23
  targetBookingDayIndex = inspectionsTime.findIndex(item => item.inspectionDayName === nowDay)
  while (continueLooking) {
    while (movingToNextDay) {
      let nowDay = suggestedTime.format('dddd')
      targetBookingDayIndex = inspectionsTime.findIndex(item => item.inspectionDayName === nowDay)
      movingToNextDay = !daysAvailableForBooking.includes(nowDay)
      if (movingToNextDay && targetBookingDayIndex === -1) {
        suggestedTime = suggestedTime.add(1, 'd')
      }
      if (targetBookingDayIndex > -1) {
        openHour = parseInt(inspectionsTime[targetBookingDayIndex].openTime.split(':')[0])
        closeHour = parseInt(inspectionsTime[targetBookingDayIndex].closeTime.split(':')[0])
        suggestedTime.hour(openHour)
      }
    }

    const isTimeTaken = events.map(item => item.date).includes(suggestedTime.toISOString())
    const closingTime = moment(suggestedTime).hour(closeHour)

    if (!isTimeTaken && suggestedTime.isBefore(closingTime) && suggestedTime.isAfter(now)) {
      continueLooking = false
    } else if (suggestedTime.isSameOrAfter(closingTime)) {
      movingToNextDay = true
      suggestedTime = suggestedTime.add(1, 'd')
    } else if (suggestedTime.isBefore(closingTime)) {
      suggestedTime = suggestedTime.add(1, 'h')
    }
  }
  return {
    date: suggestedTime.toISOString(),
    id
  }
}
