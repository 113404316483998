import styles from './property.module.scss'
import React from 'react'

const Badge = ({ children, parent }) => {
  let badgeStyle = styles[`badge_${parent}`]
  return (
    <div className={badgeStyle}>
      <span>{children}</span>
    </div>
  )
}
export default Badge
