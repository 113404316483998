import PropertyCard from './card'
import Pagination from '../common/pagination'
import React from 'react'
import { formatAddress } from './util'

const List = ({ properties }) => (
  <div className="row">
    {properties.map((property, index) => {
      const street = formatAddress(property.locationAddress[0])
      const images = property.gallerySection[0].album.map(image => image.responsiveImage.src)
      return (
        <div className="col-12 col-lg-4" key={index}>
          <PropertyCard
            index={index}
            images={images}
            toilets={property.toilets}
            beds={property.bedrooms}
            baths={property.bathrooms}
            propertyType={property.propertyType}
            isFav={property.fav || false}
            street={street}
            slug={property.slug}
            id={property.id}
          />
        </div>
      )
    })}
  </div>
)

export default Pagination(List)
