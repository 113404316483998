import React from 'react'
import { Link } from 'gatsby'
import { DASHBOARD } from '../../routes'

/**
 * Empty component to be shown when no results are found
 * @param {Object} props - Component's props
 * @param {string} props.message - No result's message
 * @param {string} [props.cta] - CTA's text (it always redirects to dashboard page)
 * @param {boolean} [props.fullWidth=false] - Flag to set component full width
 * @return {function(): JSX.Element}
 * @constructor
 */
const Empty = ({ message, cta, fullWidth }) => (
  <div className={fullWidth ? '' : 'my-5 py-5'}>
    <div className="row">
      <div className={`${fullWidth ? '' : 'col-lg-8 mx-auto py-lg-5'} col text-center`}>
        <div className="card shadow">
          <div className="row">
            <div className={`${fullWidth ? 'col-lg-7' : 'col-lg-6'} col py-5 mx-auto`}>
              <div className="px-2 px-lg-0">
                <p className={`${cta ? 'mb-5' : 'h2'} text-gray`}>{message}</p>
                {cta && (
                  <Link className="btn btn-primary btn-lg" to={DASHBOARD}>
                    {cta}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Empty
