import React, { useEffect, useState } from 'react'
import SearchInput from './searchField'
import style from './search.module.scss'
import { SEARCH } from '../icons'
import queryString from 'query-string'
import * as Routes from '../../routes'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

const Option = ({ id, options, handleOnChange, defaultValue, value, required }) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{defaultValue}</label>
      <select
        name={id}
        id={id}
        className={`form-control`}
        onChange={e => handleOnChange(e)}
        onBlur={e => handleOnChange(e)}
        value={value}
        required={required}
      >
        <option disabled value="" hidden>
          {defaultValue}
        </option>
        {options.map((option, index) => {
          return (
            <option value={option} key={index}>
              {option}
            </option>
          )
        })}
      </select>
    </div>
  )
}

const SearchForm = ({ children }) => {
  const query = queryString.parse(useLocation().search)
  const [isBuying, setBuying] = useState(false)
  const [address, setAddress] = useState({
    formatted_address: query.formatted_address,
    vicinity: query.vicinity,
    lat: query.lat,
    lng: query.lng
  })
  const [filters, setFilters] = useState({
    type: query.type,
    bedrooms: query.bedrooms,
    bathrooms: query.bathrooms,
    preference: query.preference,
    priceMax: query.priceMax,
    priceMin: query.priceMin
  })
  const searchHandle = e => {
    e.preventDefault()
    navigate(`${Routes.DASHBOARD}?${queryString.stringify(filters)}&${queryString.stringify(address)}`)
  }

  const PropertyTypes = ['Any', 'House', 'Apartment & Unit', 'Townhouse', 'Villa', 'Commercial']
  const Bedrooms = ['Any', 'Studio', '1', '2', '3', '4', '5', '5+']
  const Bathrooms = ['Any', 'Studio', '1', '2', '3', '4', '5', '5+']
  const LeasingPrices = ['Any', '$200', '$300', '$400', '$500', '$600', '$700', '$800', '$900', '$1,000', '+ $1,000']
  const BuyingPrices = ['Any', '$50,000', '$75,000', '$100,000', '$125,000', '$150,000', '$175,000', '$200,000']
  const handleFilter = e => {
    let filterName = e.target.name
    let filterValue = e.target.value
    setFilters({ ...filters, [filterName]: filterValue })
  }
  const handlePreference = value => setFilters({ ...filters, preference: value })

  useEffect(() => {
    setBuying(filters.preference === 'Selling')
  }, [filters.preference])

  return (
    <form id="searchForm" className="order-lg-0 mb-3" onSubmit={e => searchHandle(e)}>
      {children}
      <div className="row align-items-end mb-lg-3">
        <div className="col-lg-4 col-12">
          <div className="row">
            <div className="col-6">
              <div className={`form-group ${style.preference}`}>
                <input
                  type="radio"
                  name="preferences"
                  id="Selling"
                  checked={'Selling' === filters.preference}
                  onChange={() => handlePreference('Selling')}
                  required
                />
                <label htmlFor={'Selling'}>Buy</label>
              </div>
            </div>
            <div className="col-6">
              <div className={`form-group ${style.preference}`}>
                <input
                  type="radio"
                  name="preferences"
                  id="Leasing"
                  checked={'Leasing' === filters.preference}
                  onChange={() => handlePreference('Leasing')}
                  required
                />
                <label htmlFor={'Leasing'}>Rent</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <SearchInput
            address={address.formatted_address}
            setAddress={setAddress}
            label="Search by state, suburb or city"
          />
        </div>
        <div className="col-lg-4 col-12">
          <Option
            id="type"
            defaultValue="Property Type"
            options={PropertyTypes}
            handleOnChange={handleFilter}
            value={filters.type}
          />
        </div>
      </div>

      <div className="row mb-lg-3">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-6">
              <Option
                id="bedrooms"
                defaultValue="Bedrooms (min)"
                options={Bedrooms}
                handleOnChange={handleFilter}
                value={filters.bedrooms}
              />
            </div>
            <div className="col-6">
              <Option
                id="bathrooms"
                defaultValue="Bathrooms (min)"
                options={Bathrooms}
                handleOnChange={handleFilter}
                value={filters.bathrooms}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-6">
              <Option
                id="priceMin"
                defaultValue={`Price ${isBuying ? '' : 'PW'} (min)`}
                options={isBuying ? BuyingPrices : LeasingPrices}
                handleOnChange={handleFilter}
                value={filters.priceMin}
              />
            </div>
            <div className="col-6">
              <Option
                id="priceMax"
                defaultValue={`Price ${isBuying ? '' : 'PW'} (max)`}
                options={isBuying ? BuyingPrices : LeasingPrices}
                handleOnChange={handleFilter}
                value={filters.priceMax}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="form-group text-center">
        <button className={`btn btn-primary ${style.search}`} type="submit">
          Search <SEARCH />
        </button>
      </div>
    </form>
  )
}

export default SearchForm
