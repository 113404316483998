import React, { useCallback, useEffect, useRef } from 'react'
import { isBrowser } from '../../services/auth'
import { loadMap } from '../../services/map'

const initMap = (handleAddress, autoCompleteRef) => {
  let input = autoCompleteRef.current
  const countryRestrict = { country: 'au' }
  let options = {
    types: ['(cities)'],
    componentRestrictions: countryRestrict
  }

  let autocomplete = new google.maps.places.Autocomplete(input, options)
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    let {
      formatted_address,
      vicinity,
      geometry: {
        location: { lat, lng }
      }
    } = place
    let addressObj = {
      formatted_address,
      vicinity,
      lat: lat(),
      lng: lng()
    }
    handleAddress(addressObj)
  })
}

const SearchInput = ({ address, setAddress, label }) => {
  const autoCompleteRef = useRef('')
  const onAddressChange = useCallback(value => setAddress(value), [setAddress])
  useEffect(() => {
    if (isBrowser()) {
      if (window.initMap === undefined) {
        loadMap(() => {
          initMap(onAddressChange, autoCompleteRef)
        })
      } else {
        initMap(onAddressChange, autoCompleteRef)
      }
    }
  }, [onAddressChange])

  return (
    <div className="form-group">
      <label htmlFor="address">{label}</label>
      <input
        type="search"
        placeholder="Find your new location"
        id="address"
        name="address"
        className="form-control"
        ref={autoCompleteRef}
        defaultValue={address}
        required
      />
    </div>
  )
}

export default SearchInput
