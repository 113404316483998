import React from 'react'
import queryString from 'querystring'
import { useLocation } from '@reach/router'
import PaginationItem from './PaginationItem'

const getChunks = (pages, currentPage) => {
  let startPages, endPages
  if (currentPage < pages && pages > 2) {
    endPages = currentPage === 1 ? currentPage + 2 : currentPage + 1
  } else {
    endPages = pages
  }
  if (currentPage > 1 && pages > 2) {
    startPages = currentPage === pages ? currentPage - 2 : currentPage - 1
  } else {
    startPages = 1
  }
  return { startPages, endPages }
}

/**
 * @typedef ListComponent
 * @type {function}
 * @param {string|number} pages - Number of pages of the component
 * @param {Object} rest - Component props
 */
/**
 * Wraps a component with pagination
 * @param {ListComponent} WrappedComponent - Component to be wrapped
 * @return {function(ListComponent): JSX.Element}
 * @constructor
 */
const Pagination = WrappedComponent => {
  const WithPagination = ({ pages, ...rest }) => {
    let location = useLocation()

    let { page: currentPage = 1 } = queryString.parse(location.search.substr(1))
    currentPage = parseInt(currentPage)
    let pageItems = []
    const { startPages, endPages } = getChunks(pages, currentPage)

    for (let i = startPages; i <= endPages; i++) {
      pageItems.push(<PaginationItem value={i} key={i} />)
    }

    return (
      <div>
        <div className="mb-5">
          <WrappedComponent {...rest} />
        </div>

        <nav aria-label="Page navigation" className="mb-5">
          <ul className="pagination">
            {currentPage > 1 && pages > 3 && <PaginationItem value={1} text={'<<'} />}

            {pageItems}

            {currentPage < pages && pages > 3 && <PaginationItem value={pages} text={'>>'} />}
          </ul>
        </nav>
      </div>
    )
  }

  WithPagination.displayName = `WithPagination(${getDisplayName(WrappedComponent)})`

  return WithPagination
}

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default Pagination
