import { HEARTH } from '../icons'
import React, { useCallback, useContext, useEffect } from 'react'
import { saveToShortList } from '../../requests/buyerHttp'
import userContext from '../../context/userContext'
import { isLoggedIn } from '../../services/auth'

const Fav = ({ fav, propertyId, setFav }) => {
  const { profile, setProfile } = useContext(userContext)
  const handleFav = useCallback(value => setFav(value), [setFav])
  const handleSaveToShortlist = async e => {
    e.stopPropagation()
    let added = !fav
    try {
      if (isLoggedIn()) {
        setFav(added)
        const { id, shortlist } = profile
        const newShortlist = fav ? shortlist.filter(id => id !== propertyId) : [...shortlist, propertyId]
        const result = await saveToShortList(id, newShortlist)
        setProfile({ ...profile, shortlist: result.shortlist })
      }
    } catch (e) {
      console.error(e.message)
      setFav(fav)
    }
  }
  useEffect(() => {
    const shortlist = profile ? profile.shortlist : []
    if (shortlist.length > 0) {
      handleFav(profile.shortlist.includes(propertyId))
    } else {
      handleFav(false)
    }
  }, [profile, propertyId, handleFav])
  return (
    <div>
      <button type="button" className="btn p-0" onClick={handleSaveToShortlist} title="Add to Shortlist" tabIndex="-1">
        <HEARTH fill={fav} />
        <span className="sr-only">Add to shortlist</span>
      </button>
    </div>
  )
}

export default Fav
